<template>
  <section class="section">
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li class="is-active">
          <a>Administração</a>
        </li>
        <li class="is-active">
          <a>Exame</a>
        </li>
      </ul>
    </nav>

    <b-loading
      :is-full-page="true"
      :active.sync="load"
      :can-cancel="false"
    ></b-loading>
    <div v-if="!quest">
      <div class="export">
        <button class="button is-primary" @click="planilha">Exportar</button>
      </div>

      <b-collapse class="card m-b-md" :open="false" aria-id="filterContent">
        <div
          slot="trigger"
          slot-scope="props"
          class="card-header"
          role="button"
          aria-controls="filterContent"
        >
          <p class="card-header-title">Filtro</p>
          <a class="card-header-icon">
            <b-icon :icon="props.open ? 'caret-down' : 'caret-up'"></b-icon>
          </a>
        </div>
        <div class="card-content">
          <b-field label="Data de Inicio">
            <b-datepicker
              :month-names="datePicker[0]"
              :day-names="datePicker[1]"
              v-model="query.initDate"
              icon="calendar-alt"
            ></b-datepicker>
          </b-field>

          <b-field label="Data de Fim">
            <b-datepicker
              :month-names="datePicker[0]"
              :day-names="datePicker[1]"
              v-model="query.endtDate"
              icon="calendar-alt"
            ></b-datepicker>
          </b-field>

          <b-field label="Login">
            <Multiselect
              v-model="query.login"
              id="ajax2"
              label="username"
              track-by="name"
              placeholder="Digite para pesquisar"
              open-direction="bottom"
              :options="searchList2"
              :multiple="false"
              :searchable="true"
              :loading="isLoadingMultiSelect"
              :internal-search="false"
              :clear-on-select="false"
              :close-on-select="true"
              :options-limit="300"
              :limit="3"
              :max-height="600"
              :show-no-results="false"
              :hide-selected="true"
              :preselect-first="false"
              @search-change="getBySearch2"
              selectLabel="Pressione enter para selecionar"
              deselectLabel="Pressione enter para desmarcar"
              selectedLabel="Selecionado"
            >
              <template slot="noOptions"
                >Lista vazia</template
              >
            </Multiselect>
          </b-field>

          <b-field label="Grupo" v-if="hasProfile('admin')">
            <b-select v-model="query.team" placeholder="Grupo">
              <option v-for="team in teams" :key="team._id" :value="team._id">{{
                team.name
              }}</option>
              <option :value="null">Nenhum</option>
            </b-select>
          </b-field>

          <b-field label="Curso">
            <b-select v-model="query.curso" placeholder="Curso">
              <option
                v-for="course in courses"
                :key="course._id"
                :value="course._id"
                >{{ course.name }}</option
              >
              <option :value="null">Nenhum</option>
            </b-select>
          </b-field>

          <!-- <b-field label="Usuário">
            <b-select v-model="query.user" placeholder="Usuário">
              <option v-for="user in users" :key="user._id" :value="user.uid">{{user.name}}</option>
            </b-select>
          </b-field>-->

          <b-field label="Usuário">
            <Multiselect
              v-model="users"
              id="ajax"
              label="name"
              track-by="name"
              placeholder="Digite para pesquisar"
              open-direction="bottom"
              :options="searchList"
              :multiple="false"
              :searchable="true"
              :loading="isLoadingMultiSelect"
              :internal-search="false"
              :clear-on-select="false"
              :close-on-select="true"
              :options-limit="300"
              :limit="3"
              :max-height="600"
              :show-no-results="false"
              :hide-selected="true"
              :preselect-first="false"
              @search-change="getBySearch"
              selectLabel="Pressione enter para selecionar"
              deselectLabel="Pressione enter para desmarcar"
              selectedLabel="Selecionado"
            >
              <template slot="noOptions"
                >Lista vazia</template
              >
            </Multiselect>
          </b-field>

          <b-field label="Exame Final">
            <b-select v-model="query.finalExame" placeholder="Exame Final">
              <option value="true">Sim</option>
              <option value="false">Não</option>
              <option :value="null">Nenhum</option>
            </b-select>
          </b-field>

          <b-field label="Aprovado">
            <b-select v-model="query.aprovado" placeholder="Aprovado">
              <option value="true">Sim</option>
              <option value="false">Não</option>
              <option :value="null">Nenhum</option>
            </b-select>
          </b-field>

          <button class="button is-primary" @click="get()">Buscar</button>
          <button
            class="button is-danger m-l-sm"
            @click="(query = {}), (users = [])"
          >
            Limpar
          </button>
        </div>
      </b-collapse>

      <h1>Total: {{ total }}</h1>

      <b-table
        :data="data"
        default-sort-direction="asc"
        :striped="true"
        :hoverable="true"
        :loading="isLoading"
        detailed
        :opened-detailed="[]"
        detail-key="_id"
      >
        <template slot-scope="props">
          <b-table-column field="nome" label="Nome" sortable>{{
            props.row.nome
          }}</b-table-column>

          <b-table-column field="login" label="Login" sortable>{{
            props.row.login
          }}</b-table-column>

          <b-table-column field="curso" label="Curso" sortable>{{
            props.row.curso
          }}</b-table-column>

          <b-table-column field="lesson" label="Lição" sortable>{{
            props.row.lesson
          }}</b-table-column>

          <b-table-column
            field="grupo"
            label="Grupo"
            v-if="hasProfile('admin')"
            sortable
            >{{ props.row.grupo }}</b-table-column
          >

          <b-table-column field="prova_Final" label="Exame final" sortable>{{
            props.row.prova_Final ? "SIM" : "NÃO"
          }}</b-table-column>

          <b-table-column field="aprovado" label="Aprovado" sortable>{{
            props.row.aprovado ? "SIM" : "NÃO"
          }}</b-table-column>

          <b-table-column field="dataCreat" label="Início prova" sortable>
            <p>
              {{ new Date(props.row.dataCreat).toLocaleDateString("pt-BR") }}
            </p>
            <p>
              {{ new Date(props.row.dataCreat).toLocaleTimeString("pt-BR") }}
            </p>
          </b-table-column>

          <b-table-column field="dataFinal" label="Envio prova" sortable>
            <p v-if="props.row.dataFinal">
              {{ new Date(props.row.dataFinal).toLocaleDateString("pt-BR") }}
            </p>
            <p v-if="props.row.dataFinal">
              {{ new Date(props.row.dataFinal).toLocaleTimeString("pt-BR") }}
            </p>
            <p v-else>Em andamento</p>
          </b-table-column>

          <b-table-column field="porcentagem" label="Média" sortable>{{
            media(props.row)
          }}</b-table-column>

          <b-table-column field="certas" label="C" sortable>{{
            props.row.certas
          }}</b-table-column>

          <b-table-column field="erradas" label="E" sortable>{{
            props.row.erradas
          }}</b-table-column>

          <b-table-column field="erradas" label="T" sortable>{{
            props.row.tentativa ? props.row.tentativa : 1
          }}</b-table-column>
        </template>

        <template v-if="hasProfile('admin')" slot="detail" slot-scope="props">
          <article class="media">
            <div class="media-content">
              <div class="content">
                <button
                  class="button is-primary  m-r-sm"
                  @click="quest = props.row"
                >
                  Prova
                </button>

                <b-button type="is-danger" @click="confirm(props)"
                  >Remover</b-button
                >
              </div>
            </div>
          </article>
        </template>
      </b-table>

      <div class="custom">
        <b-pagination
          class="m-t-md"
          :total="total"
          :current.sync="current"
          :simple="false"
          :rounded="false"
          :per-page="perPage"
          aria-next-label="Próxima pagina"
          aria-previous-label="Pagina anterior"
          aria-page-label="Page"
          aria-current-label="Pagina atual"
        ></b-pagination>
      </div>
    </div>
    <DetailExame
      v-if="quest"
      :quest="quest"
      @close="quest = null"
    ></DetailExame>
  </section>
</template>

<script>
import Axios from "axios";
import { debounce } from "lodash";
import XLSX from "xlsx";

import { hasProfile } from "@/global/auth";
import DetailExame from "./DetailExame";
import Multiselect from "vue-multiselect";
export default {
  components: {
    Multiselect,
    DetailExame,
  },
  data() {
    return {
      hasProfile,
      quest: null,
      me: null,
      data: [],
      teams: [],
      users: [],
      query: {},
      courses: {},
      total: "",
      current: 1,
      perPage: "",
      isLoading: false,
      isLoadingMultiSelect: false,
      datePicker: [
        [
          "Janeiro",
          "Fevereiro",
          "Março",
          "Abril",
          "Maio",
          "Junho",
          "Julho",
          "Agosto",
          "Setembro",
          "Outubro",
          "Novembro",
          "Dezembro",
        ],
        ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
      ],
      searchList: [],
      searchList2: [],
      load: null,
    };
  },
  methods: {
    async planilha() {
      // let wb = XLSX.utils.table_to_book(document.getElementsByClassName("table"));

      let sunrise, sunset;
      if (this.query.initDate) {
        sunrise = new Date(this.query.initDate);
        sunrise.setHours(0);
        sunrise.setMinutes(0);
        sunrise.setSeconds(0);
        sunrise.toISOString();
      }

      if (this.query.endtDate) {
        sunset = new Date(this.query.endtDate);
        sunset.setHours(23);
        sunset.setMinutes(59);
        sunset.setSeconds(59);
        sunset.toISOString();
      }

      this.load = true;
      let status = [];
      let vezes = Math.round(this.total / 1000);
      if (this.total > 0 && this.total <= 1000) vezes = 1;
      for (let index = 0; index < vezes; index++) {
        let chama = await Axios.get(
          `${
            this.me ? "/v1/exams/team/" + this.me.teams[0] : "/v1/exams"
          }?limit=1000&page=${index + 1}${
            this.users._id ? "&user=" + this.users._id : ""
          }${this.query.team ? "&team=" + this.query.team : ""}${
            this.query.curso ? "&course=" + this.query.curso : ""
          }${this.query.initDate ? "&createdAt[$gte]=" + sunrise : ""}${
            this.query.endtDate ? "&createdAt[$lte]=" + sunset : ""
          }${
            this.query.finalExame ? "&finalExam=" + this.query.finalExame : ""
          }${this.query.aprovado ? "&approved=" + this.query.aprovado : ""}${
            this.query.login ? "&user=" + this.query.login.username : ""
          }`
        );

        for (const iterator of chama.data.data) {
          status.push(iterator);
        }
      }

      status = status.map((item) => {
        return {
          nome: item.user.name,
          login: item.user.username,
          grupo:
            item.user && item.user.teams && item.user.teams[0]
              ? item.user.teams[0].name
              : "",
          curso: item.course.name,
          Prova_Final: item.finalExam ? "SIM" : "NÃO",
          Aprovado: item.approved ? "SIM" : "NÃO",
          Data_da_criacao:
            new Date(item.createdAt).toLocaleDateString("pt-BR") +
            " " +
            new Date(item.createdAt).toLocaleTimeString("pt-BR"),
          Data_da_finalizacao:
            new Date(item.finalizedAt).toLocaleDateString("pt-BR") +
            " " +
            new Date(item.finalizedAt).toLocaleTimeString("pt-BR"),
          Porcentagem: item.totalPercent + "%",
          Certas: item.totalCorrect,
          Erradas: item.totalWrong,
        };
      });

      let ws = XLSX.utils.json_to_sheet(status);
      let wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "People");
      XLSX.writeFile(wb, "report.xlsx");
      this.load = null;
    },
    getBySearch: debounce(function() {
      this.isLoadingMultiSelect = true;
      if (arguments[0]) {
        Axios.get(
          `/v1/${this.me ? "users/team/" + this.me.teams[0] : "users"}?name=${
            arguments[0]
          }`
        )
          .then((res) => {
            this.searchList = res.data.data;
            this.isLoadingMultiSelect = false;
          })
          .catch((erro) => {
            this.isLoadingMultiSelect = false;
            console.error(erro);
          });
      }
      this.isLoadingMultiSelect = false;
    }, 500),
    getBySearch2: debounce(function() {
      this.isLoadingMultiSelect = true;
      if (arguments[0]) {
        Axios.get(
          `/v1/${
            this.me ? "users/team/" + this.me.teams[0] : "users"
          }?username=${arguments[0]}`
        )
          .then((res) => {
            this.searchList2 = res.data.data;
            this.isLoadingMultiSelect = false;
          })
          .catch((erro) => {
            this.isLoadingMultiSelect = false;
            console.error(erro);
          });
      }
      this.isLoadingMultiSelect = false;
    }, 500),
    get() {
      this.isLoading = true;
      let sunrise, sunset;
      if (this.query.initDate) {
        sunrise = new Date(this.query.initDate);
        sunrise.setHours(0);
        sunrise.setMinutes(0);
        sunrise.setUTCSeconds(0);
        sunrise = sunrise.toISOString();
      }

      if (this.query.endtDate) {
        sunset = new Date(this.query.endtDate);
        sunset.setHours(23);
        sunset.setMinutes(59);
        sunset.setSeconds(59);
        sunset = sunset.toISOString();
      }

      Axios.get(
        `${
          this.me ? "/v1/exams/reports/team/" + this.me.teams[0] : "/v1/exams"
        }?page=${this.current}${
          this.users._id ? "&user=" + this.users._id : ""
        }${this.query.team ? "&team=" + this.query.team : ""}${
          this.query.curso ? "&course=" + this.query.curso : ""
        }${this.query.initDate ? "&createdAt[$gte]=" + sunrise : ""}${
          this.query.endtDate ? "&createdAt[$lte]=" + sunset : ""
        }${this.query.finalExame ? "&finalExam=" + this.query.finalExame : ""}${
          this.query.aprovado ? "&approved=" + this.query.aprovado : ""
        }${this.query.login ? "&user=" + this.query.login._id : ""}`
      )
        .then((data) => {
          this.data = data.data.data.map((item) => {
            return {
              nome: item.user.name,
              login: item.user.username,
              grupo:
                item.user && item.user.teams && item.user.teams[0]
                  ? item.user.teams[0].name
                  : "",
              curso: item.course.name,
              prova_Final: item.finalExam,
              aprovado: item.approved,
              dataCreat: item.createdAt,
              dataFinal: item.finalizedAt,
              porcentagem: item.totalPercent,
              certas: item.totalCorrect,
              erradas: item.totalWrong,
              lesson: item.lesson.name,
              extra: item.extra,
              tentativa: item.attemptsMade,
              _id: item._id,
            };
          });

          this.total = data.data.total;
          this.perPage = data.data.limit;
          this.isLoading = false;
          this.load = false;
        })
        .catch((erro) => {
          this.isLoading = false;
          console.error(erro);
        });
    },
    media(props) {
      if (props.extra && props.extra.finalizeBy)
        return "Finalizado pelo sistema";
      return props.porcentagem || props.porcentagem === 0
        ? props.porcentagem.toFixed(2) + "%"
        : "";
    },
    getUser() {
      Axios.get(`/v1/users/`)
        .then((data) => {
          if (data.data) {
            this.users = data.data.data;
          }
        })
        .catch((erro) => {
          console.error(erro);
        });
    },
    getCourse() {
      Axios.get(`/v1/courses/`)
        .then((data) => {
          if (data.data) {
            this.courses = data.data.data;
          }
        })
        .catch((erro) => {
          console.error(erro);
        });
    },
    getTeam() {
      Axios.get(`/v1/teams/`, { params: { limit: 50 } })
        .then((data) => {
          if (data.data) {
            this.teams = data.data.data;
          }
        })
        .catch((erro) => {
          console.error(erro);
        });
    },
    getMe() {
      Axios.get(`/v1/users/me`)
        .then((data) => {
          this.me = data.data;
          this.get();
        })
        .catch((erro) => {
          console.error(erro);
        });
    },
    confirm(data) {
      this.$buefy.dialog.confirm({
        message: "Tem certeza que deseja <b>excluir</b> este exame?",
        cancelText: "Cancelar",
        confirmText: "Confirmar",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.remove(data),
      });
    },
    remove(data) {
      Axios.delete(`/v1/exams/${data.row._id}`)
        .then((data) => {
          if (data.data) {
            this.get();
            this.$buefy.notification.open({
              message: "Exame excluida com sucesso!",
              type: "is-success",
            });
          }
        })
        .catch((erro) => {
          console.error(erro);
        });
    },
  },
  watch: {
    current(current) {
      if (!current) return;
      this.get(current);
    },
  },
  mounted() {
    this.hasProfile("manager") ? this.getMe() : this.get();
    this.getCourse();
    if (this.hasProfile("admin")) this.getTeam();
    this.load = true;
  },
};
</script>

<style lang="scss" scoped>
.custom {
  display: flex;
  justify-content: flex-end;
}
.b-table {
  overflow-x: auto;
}
.export {
  margin-bottom: 1rem;
  text-align: right;
  width: 100%;
}
</style>
