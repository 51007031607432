<template>
  <section class="section container" id="exame">
    <div class="is-flex">
      <div v-if="data && data.user">
        <p>
          Usuário: <b>{{ data.user[0].name }}</b>
        </p>
        <p>
          Curso: <b>{{ data.course[0].name }}</b>
        </p>
        <p class="m-b-md">
          Lição: <b>{{ data.lesson[0].name }}</b>
        </p>
      </div>
      <div v-show="showButtons">
        <button
          tag="button"
          class="button is-primary m-r-sm"
          @click="spreadsheet"
        >
          Exportar Excel
        </button>
        <button
          tag="button"
          class="button is-primary m-r-sm"
          @click="generatePDF"
        >
          Exportar PDF
        </button>
        <button tag="button" class="button is-primary" @click="$emit('close')">
          Voltar
        </button>
      </div>
    </div>
    <div v-if="data && data.question">
      <div class="box" v-for="(exercice, index) in data.question" :key="exercice._id">
        <h1>{{"Questão " + parseInt(index+1)}}</h1>
        <p class="m-b-sm">{{exercice.title}}</p>
        <p class="m-b-sm">{{exercice.question}}</p>
        <div class="radio-content" v-for="choice in exercice.choice" :key="choice">
          <div v-if="exercice.type === 'choice'">
            <b-checkbox
              :class="[
                exercice.answer[0] === choice ? 'green' : '',
                exercice.userAnswer[0] === choice && !exercice.isCorrect
                  ? 'red'
                  : '',
              ]"
              :value="exercice.userAnswer[0] === choice ? true : ''"
              disabled
              >{{ choice }}</b-checkbox
            >
          </div>
          <div v-if="exercice.type === 'multiple_choice'">
            <b-checkbox
              @input="addNewTodo($event, choice, index)"
              :native-value="choice"
              >{{ choice }}</b-checkbox
            >
          </div>
        </div>
        <div v-if="exercice.type === 'true_or_false'">
          <b-checkbox
            :class="[exercice.answer[0] === choice ? 'green' : '']"
            :value="exercice.userAnswer[0] === choice ? true : ''"
            disabled
            >Verdadeiro</b-checkbox
          >
          <b-checkbox
            :class="[exercice.answer[0] === choice ? 'green' : '']"
            :value="exercice.userAnswer[0] === choice ? true : ''"
            disabled
            >Falso</b-checkbox
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Axios from "axios";
import XLSX from "xlsx";
import * as html2pdf from "html2pdf.js";

export default {
  props: {
    quest: Object,
  },
  data() {
    return {
      data: [],
      showButtons: true,
    };
  },
  methods: {
    getExame() {
      Axios.get(`/v1/exams/reports/${this.quest._id}`)
        .then((data) => {
          if (data.data) {
            this.data = data.data;
          }
        })
        .catch((erro) => {
          console.error(erro);
        });
    },
    toString(item) {
      let temp = "";

      item.forEach((e) => {
        temp += e + " ";
      });

      return temp;
    },
    spreadsheet() {
      let temp = this.data.question.map((item) => {
        return {
          nome: this.data.user[0].name,
          login: this.data.user[0].username,
          titulo: item.title,
          resposta_usuario: item.userAnswer[0],
          resposta_correta: item.answer[0],
          alternativas: this.toString(item.choice),
        };
      });

      let ws = XLSX.utils.json_to_sheet(temp);
      let wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "People");
      XLSX.writeFile(wb, "report.xlsx");
    },
    generatePDF() {
      this.showButtons = false;

      const element = document.getElementById("exame");

      const opt = {
        pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
        margin: 1,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };

      html2pdf()
        .set(opt)
        .from(element)
        .save(`exame-${this.data.user[0].username}.pdf`)
        .then(() => {
          this.showButtons = true;
        });
    },
  },
  mounted() {
    this.getExame();
  },
};
</script>

<style scoped>
.is-disabled {
  opacity: 1 !important;
}
.red {
  color: red;
}
.green {
  color: green;
}
.is-flex {
  justify-content: space-between;
}
</style>
