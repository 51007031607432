import { render, staticRenderFns } from "./Exame.vue?vue&type=template&id=08631083&scoped=true&"
import script from "./Exame.vue?vue&type=script&lang=js&"
export * from "./Exame.vue?vue&type=script&lang=js&"
import style0 from "./Exame.vue?vue&type=style&index=0&id=08631083&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08631083",
  null
  
)

export default component.exports